import { TextField, TextFieldProps } from '@mui/material'
import { FC } from 'react'

interface DurationFieldProps {
  onChange: (value: number) => void
}

export const DurationField: FC<Omit<TextFieldProps, 'onChange'> & DurationFieldProps> = ({ label, value, onChange, ...props }) => {
  return (
    <TextField
      label={label ?? 'Duração'}
      type='number'
      value={value}
      onChange={e => onChange(+e.target.value)}
      inputProps={{ step: 10 }}
      {...props}
    />
  )

}
